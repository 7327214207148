@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  padding: 0px;
  background-color: #f7f7f7;
}
*::-webkit-scrollbar {
  width: 4px;
}
.rhap_play-status--playing .rhap_progress-filled {
  background-color: #ff8c21;
}
.rhap_play-status--paused .rhap_progress-filled {
  background-color: #ff8c21;
}
.rhap_play-status--paused .rhap_progress-indicator {
  background-color: #ff8c21;
}
.rhap_play-status--playing .rhap_progress-indicator {
  background-color: #ff8c21;
}
.rhap_main-controls-button {
  /* background-color: #ff8c21 !important; */
  color: #ffffff !important;
}
.rhap_repeat-button {
  color: white !important;
}
.rhap_time {
  color: white !important;
}
.rhap_volume-button {
  color: white !important;
}
*::-webkit-scrollbar-track {
  /* background: white; */
  /* border-radius: 5px; */
}
.rhap_play-status--paused .rhap_progress-bar {
  background-color: rgb(240, 240, 240);
  height: 3px !important;
}
.rhap_play-status--playing .rhap_progress-bar {
  background-color: rgb(240, 240, 240);
  height: 3px !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #ff8c21;
  border-radius: 5px;
  border: 1px solid #ff8c21;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.swiper-button-next{
  visibility: hidden !important;
}
.swiper-button-prev{
  visibility: hidden !important;
}
.swiper-pagination-bullet{
  visibility: hidden !important;
}
.swiper-pagination-bullet-active{
  visibility: hidden !important;
}
